.logo {
  height: 60px;
}

#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  /* background: rgba(255, 255, 255, 0.3); */
}

.ant-layout-content {
  width: calc(100% - 32px);
  overflow: auto;
}

.item {
  font-size: 30px;
}

.item-title {
  font-size: 30px;
}

.item-value {
  margin-left: 50px;
  font-size: 40px;
}

.line-chart-container {
  margin-top: 10px;
  min-width: 300px;
}

.site-header {
  text-align: right;
}

.site-header .avatar {
  margin-right: 20px;
}

.users-ov-row .users-ov-item {
  margin-left: 10px;
  margin-top: 10px
}

.users-ov-row .users-ov-item .users-ov-item-value {
  margin-left: 5px;
  font-weight: 900;
}

.users-ov-row .users-ov-item .seed-user {
  color: rgb(129, 216, 207);
}

.log-container {
  white-space: pre-wrap;
}

.line-chart-container {
  margin-top: 10px;
}

.ptc-client-detail-container {
  height: 80vh;
  overflow: auto;
}
